<template>
  <div class="activityDetailContainer">

    <div class="contentWidth ignore_view">
      <a-breadcrumb class="mt40 mb25">
        <a-breadcrumb-item>
          <r-link
            to="/"
            class="font16 cursorPointer color707070 primaryHover commontransition"
          >Home</r-link></a-breadcrumb-item>
        <a-breadcrumb-item><span class="font16 color333">{{ title }}</span></a-breadcrumb-item>
      </a-breadcrumb>
      <a-carousel effect="fade" autoplay>
        <div v-for="(item, index) in images" :key="`caroul${index}`" class="bannerItem">
          <img class="poster" :src="item.imageUrl" fit="cover" @click="onBannerClick(item)">
        </div>
      </a-carousel>
      <div class="font20 fontBold mb35 mt40">{{ title }}</div>
      <div>
        <a-row :gutter="20">
          <a-col v-for="item in products" :key="item.id" :span="computedSpan">
            <div class="mb20">
              <transition name="el-fade-in-linear">
                <activity-item :product="item" />
              </transition>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityItem from '../../components/product_item'
import { activityDetail } from '@/api/activity'
import { mapGetters } from 'vuex'

export default {
  components: {
    ActivityItem
  },
  props: {
    handle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: '',
      images: [],
      products: [],
      metaTitle: '',
      metaKeywords: '',
      metaDescription: ''
    }
  },
  computed: {
    ...mapGetters('ConfigModule', ['computedSpan'])
  },
  mounted() {
    this.ssrCountSet(1)
    this.getActivityDetail().finally(() => {
      this.ssrCountDown()
    })
  },
  methods: {
    async getActivityDetail() {
      var response = await activityDetail({ handle: this.handle })
      if (response.code === 200) {
        this.title = response.data.title
        this.images = response.data.images || []
        this.products = response.data.products || []
        this.metaTitle = response.data.metaTitle
        this.metaKeywords = response.data.metaKeywords
        this.metaDescription = response.data.metaDescription
        if (this.metaTitle) {
          this.updateMetaInfo({
            title: this.metaTitle,
            meta: [
              {
                name: 'keywords',
                content: this.metaKeywords
              },
              {
                name: 'description',
                content: this.metaDescription
              }
            ]
          })
        }
      }
    },
    onBannerClick(item) {
      if (item.jumpUrl) {
        window.open(item.jumpUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/less/theme.less";
.activityDetailContainer {
  .bannerItem{
    overflow: hidden;
    border-radius: 12px;
  }
  .color707070 {
    color: #707070 !important;
  }
  .contentWidth {
    width: @pageWidth;
    margin: 0 auto;
    .poster {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
